<template>
  <div>
    <list-settings @belovedItems="v => (belovedItems = v)" />
    <list-search
      class="mb-4"
      :searching="searching"
      @collapse="v => (advancedCollapsed = v)"
    />
    <div class="item-list">
      <div v-if="loading">
        <base-spinner large class="mb-32" />
      </div>
      <div v-else class="mb-8 mb-tablet-32">
        <search
          v-if="(highlightQuery || advanced) && activeSearch"
          searchApi="pm_ordinance_search"
          :filters="pmordinanceFilters"
          :mapItems="mapPMOrdinancesItems"
          :advancedCollapse="advancedCollapsed"
          @advanced-open="advancedCollapsed = false"
          sublist
          #default="{items, advanced}"
          @loading="
            v => {
              this.searching = v;
            }
          "
        >
          <p-m-ordinances-list
            :items="
              belovedItems
                ? items.filter(item => belovedItems.some(b => b.id === item.id))
                : items
            "
            :searchHighlights="{
              query: `v0/pm_ordinances/load_search_highlights/:id?${highlightQuery}`,
              keywords: highlightKeywords,
            }"
            :narrow="advanced && !advancedCollapsed"
            @toggle-item="advancedCollapsed = $event"
            class="mb-4"
          />
        </search>
        <template v-else>
          <p-m-ordinances-list
            :items="belovedItems ? belovedItems : items"
            class="mb-4"
          />

          <base-more-button
            v-if="items.length < count"
            @click="loadNext"
            :loading="loadingPage"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import PMOrdinancesList from './PMOrdinancesList';
import { mapPMOrdinancesItems } from './mapPMOrdinancesItems';
import ListSettings from '@/components/ListSettings.vue';
import ListSearch from '@/views/Search/components/ListSearch.vue';
import Search from '@/views/Search/Search.vue';
import { pmordinanceFilters } from '@/views/Search/pages/pmordinanceFilters';
import {
  highlightQuery,
  highlightKeywords,
} from '@/views/Search/util/highlights';

export default {
  components: {
    Search,
    ListSearch,
    ListSettings,
    PMOrdinancesList,
  },
  metaInfo() {
    return {
      title: 'Zarządzenia Prezesa Rady Ministrów',
      link: [
        {
          rel: 'canonical',
          href: `${window.location.origin}${this.$route.fullPath}`,
        },
      ],
    };
  },
  data() {
    return {
      pmordinanceFilters,
      mapPMOrdinancesItems,
      loading: false,
      loadingPage: false,
      items: [],
      page: 1,
      count: 0,
      belovedItems: null,
      advancedCollapsed: false,
      searching: false,
    };
  },
  computed: {
    advanced() {
      return this.$route.query.advanced;
    },
    activeSearch() {
      return this.$store.state.isSearchActive;
    },
    highlightQuery: function() {
      return this.$route && this.$route.query && this.$route.query.combo
        ? `combo_keyword=${this.$route.query.combo}`
        : highlightQuery(pmordinanceFilters);
    },
    highlightKeywords: function() {
      return this.$route && this.$route.query && this.$route.query.combo
        ? [this.$route.query.combo]
        : highlightKeywords(pmordinanceFilters);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.$store.dispatch('bookmarks/fetchPMOrdinances');
      await this.loadPage();
      this.loading = false;
    },
    async loadPage() {
      const url =
        this.page === 1
          ? 'v1/pm_ordinance_header/'
          : `v1/pm_ordinance_header/?page=${this.page}`;
      const { data } = await this.$api.get(url);
      this.count = data.count;
      this.page++;
      this.items = this.items.concat(mapPMOrdinancesItems(data.results));
    },
    async loadNext() {
      if (this.page > 2 && !this.$store.getters['user/isLoggedIn']) {
        this.$store.commit('openGuestModal');
        return;
      }
      const scrollPosition = Math.round(window.scrollY);
      this.loadingPage = true;
      await this.loadPage();
      this.loadingPage = false;
      this.$nextTick(() => {
        window.scrollTo(0, scrollPosition);
      });
    },
  },
};
</script>
